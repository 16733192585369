<template>
  <div class="menu">
    <el-aside :width="asideWidth" class="menu-aside">
      <transition name="menu-fade" mode="out-in">
        <el-menu :unique-opened="true" router :default-active="defaultActive" class="el-menu-vertical-demo"
          :collapse="isCollapse" key="menu">
          <template v-for="(item, index) in menus">
            <el-submenu :index="index + ''" :key="index" v-if="!item.hidden && (usergroup === '管理员' || !item.admin)">
              <template slot="title">
                <i :class="item.iconClass"></i>
                <span>{{ item.name }}</span>
              </template>
              <template v-for="(child, index) in item.children">
                <el-menu-item-group v-if="(realname === '未实名' || !child.rename)" :key="index">
                  <el-menu-item :index="child.path">
                    <i :class="child.iconClass"></i>
                    {{ child.name }}
                  </el-menu-item>
                </el-menu-item-group>
              </template>
            </el-submenu>
          </template>
          <el-row
            style="position: absolute; bottom: 75px; width: 65%; left: 0; right: 0; margin: auto; text-align: center; z-index: 1;">
            <el-col :xs="0" :sm="24">
              <span style="color: #606266; font-size: 14px;">{{ text }}</span>
            </el-col>
          </el-row>
        </el-menu>
      </transition>
      <!-- 折叠按钮 -->
      <el-button class="collapse-button" @click="toggleCollapse">
        <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </el-button>
    </el-aside>
  </div>
</template>

<script>
import axios from 'axios';
import { getToken } from "@/utils/setToken.js";
export default {
  data() {
    return {
      text: '',
      menus: [],
      defaultActive: "/home/one",
      isCollapse: false,
      asideWidth: '250px',
      usergroup: '普通用户',
      realname: '未实名',
    };
  },
  created() {
    this.fetchText();
    this.menus = [...this.$router.options.routes];
    this.defaultActive = location.pathname
    this.usergroup = getToken('usergroup');
    this.realname = getToken('realname');
  },
  methods: {
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.asideWidth = this.isCollapse ? '65px' : '250px';
    },
    handleOpen(index, indexPath) {
      console.log(index, indexPath);
    },
    handleClose(index, indexPath) {
      console.log(index, indexPath);
    },

    fetchText() {
      axios.get('https://uapis.cn/api/say')
        .then(response => {
          this.text = response.data;
        })
        .catch(error => {
          console.error('Error fetching text:', error);
        });
    }
  },
};
</script>

<style lang="less" scoped>
.menu {
  height: 100%;
  z-index: 0;

  .el-aside {
    height: 100%;

    .el-menu {
      height: 100%;

      .el-menu-item,
      .el-submenu {
        text-align: left;
      }

      .bh {
        margin-right: 10px;
        font-size: 18px;
        color: #272727;
      }
    }

    .el-submenu .el-menu-item {
      min-width: 0;
    }

    .centered-text {
      color: #606266;
      font-size: 14px;
      text-align: center;
    }
  }

  .collapse-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .menu-aside {
    position: relative;
  }

  .menu-fade-enter-active,
  .menu-fade-leave-active {
    transition: all 1s;
  }

  .menu-fade-enter,
  .menu-fade-leave-to {
    opacity: 0;
  }
}
</style>
