<template>
    <div class="header">
        <el-header>
            <div class="title">
                <el-image style="width: 32px; height: 32px;" src="https://chmlfrp.cn/favicon.ico" fit="cover"></el-image>
                ChmlFrp v2
            </div>
            <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                    <div class="block">
                        <el-avatar :size="45" :src="circleUrl"></el-avatar>
                    </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-header>
    </div>
</template>
<script>
import { getToken, removeToken } from '@/utils/setToken.js'

export default {
    data() {
        return {
            name: ''
        }
    },
    created() {
        this.name = getToken('username')
        this.circleUrl = getToken('userimg')
    },
    methods: {
        handleCommand(command) {
            if (command === 'logout') {
                // 退出api
                this.$message.success('成功退出')
                // 清除token
                removeToken('token');
                removeToken('username');
                removeToken('userid');
                removeToken('userimg');
                removeToken('email');
                removeToken('tunnel');
                removeToken('usergroup');
                removeToken('qq');
                removeToken('bandwidth');
                removeToken('realname');
                removeToken('integral');
                removeToken('term');

                localStorage.removeItem("back_token")
                this.$router.push('/login');
            }
        }
    },
}
</script>
<style lang="less" scoped>
.header {
    width: 100%;

    .el-header {
        background: #FFFFFF;
        color: #171717;
        line-height: 50px;
        display: flex;
        justify-content: space-between;

        .title {
            width: 250px;
            font-size: 30px;
            color: #409EFF;
        }
    }

    .block {
        position: absolute;
        top: 5px;
        left: -50px;
    }
}
</style>