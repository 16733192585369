<template>
  <div class="home" :style="backgroundStyle">
    <div :style="Element_transparency_Data">
      <Header />
    </div>
    <el-container class="content" :style="Element_transparency_Data">
      <Menu />
      <el-container>
        <el-main>
          <div>
            <Bread />
          </div>
          <div class="cont">
            <router-view></router-view>
          </div>
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getToken } from '../utils/setToken';
import { isLoggedIn } from '../utils/auth'
import Header from './common/Header'
import Footer from './common/Footer'
import Menu from './common/Menu'
import Bread from './common/Breadcrumb'

export default {
  components: {
    Header,
    Footer,
    Menu,
    Bread,
  },
  data() {
    return {
      background_img: '',
      Element_transparency: 1,
    }
  },
  created() {
    this.background_img = getToken("background_img")
    this.Element_transparency = getToken('Element_transparency')
  },
  computed: {
    backgroundStyle() {
      // 根据 background_img 的值来设置背景图片
      return {
        'background-color': this.background_img ? 'transparent' : '#f0f0f0',
        'background-image': this.background_img ? `url('${this.background_img}')` : 'none',
        'background-size': this.background_img ? 'cover' : 'auto',
        'background-position': this.background_img ? 'center' : 'auto',
      };
    },
    Element_transparency_Data() {
      // 根据 Element_transparency 的值来设置元素透明度
      return {
        'opacity': this.Element_transparency ? `${this.Element_transparency}` : '1',
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    // 检查用户是否已登录
    if (isLoggedIn()) {
      // 如果用户已登录，允许访问请求的路由
      next();
    } else {
      // 如果用户未登录，重定向到登录页面
      next('/login');
    }
  },
}
</script>

<style lang="less">
.home {
  width: 100%;
  height: 100%;

  .content {
    position: absolute;
    width: 100%;
    top: 60px;
    bottom: 0;

    .cont {
      margin: 15px 0;
    }
  }
}
</style>