<template>
    <div class="footer">
        <el-card>
            Copyright © 2021 - 2024 ChmlFrp All rights reserved.
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>
.footer {
  height: 100%;
}
</style>
